<script>
import combineProduct from "@/util/combineProduct.js";
import findProductInCart from "@/util/findProductInCart.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "ProductTile",
  props: {
    product: {
      type: Object,
      required: true,
    },
    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    colorIds: {
      type: Object,
      default: () => {
        return {};
      },
    },
    initalColorUuid: {
      type: String,
      default: "",
    },
  },
  data() {
    let initalColorIndex = 0;
    if (this.initalColorUuid) {
      for (let index in this.product.colors) {
        const color = this.product.colors[index];
        if (color.uuid == this.initalColorUuid) {
          initalColorIndex = index;
          break;
        }
      }
    }

    return {
      currentColorIndex: initalColorIndex,
      loading: false,
    };
  },
  computed: {
    filteredColors() {
      const colors = this.product.colors || [];
      return colors.filter((color) => this.filterColor(color));
    },
    currentColor() {
      return this.filteredColors[this.currentColorIndex];
    },
    currentColorUrl() {
      return (
        this.product.url +
        (this.currentColorIndex > 0 ? "/color/" + this.currentColor.uuid : "")
      );
    },
    сanBeBought() {
      return !this.currentColor.not_available && this.currentColor.price > 0;
    },
    isInFavorites() {
      const favoritesId = this.product.id + ":" + this.currentColor.uuid;
      return this.favorites.indexOf(favoritesId) !== -1;
    },
    ...mapState(["cart", "favorites"]),
  },
  watch: {
    filteredColors: {
      deep: true,
      handler() {
        this.currentColorIndex = 0;
      },
    },
  },
  methods: {
    parseColorsList(list) {
      return list.split(",").map((item) => this.colorIds[item.trim()]);
    },
    filterColor(color) {
      if (this.filter && this.filter.price) {
        const price = this.filter.price
          .split(",")
          .map((value) => parseFloat(value));
        if (color.price < price[0] || color.price > price[1]) {
          return false;
        }
      }

      if (
        this.filter &&
        this.filter.availability &&
        this.filter.availability == "in-stock"
      ) {
        if (color.not_available) {
          return false;
        }
      }

      if (this.filter && this.filter.colors) {
        const filterColors = this.parseColorsList(this.filter.colors);
        if (!filterColors.includes(color.color_group)) {
          return false;
        }
      }

      return true;
    },
    setCurrentColor(index) {
      if (this.currentColorIndex != index) {
        this.loading = true;
      }

      this.currentColorIndex = index;
    },
    openFastView() {
      this.$root.openProductFastView(
        parseInt(this.product.id),
        this.currentColor.uuid
      );
    },
    isProductInCart(productId, colorId) {
      return findProductInCart(this.cart, productId, colorId) !== null;
    },
    addToFavorites() {
      const favoritesId = this.product.id + ":" + this.currentColor.uuid;
      this.addProductToFavorites(favoritesId);
    },
    removeFromFavorites() {
      const favoritesId = this.product.id + ":" + this.currentColor.uuid;
      const index = this.favorites.indexOf(favoritesId);
      this.removeProductFromFavorites(index);
    },
    toggleInFavorites() {
      if (this.isInFavorites) {
        this.removeFromFavorites();
      } else {
        this.addToFavorites();
      }
    },
    buy() {
      this.$store.dispatch(
        "addProductToCart",
        combineProduct(this.product, this.currentColor)
      );
    },
    ...mapActions(["addProductToFavorites", "removeProductFromFavorites"]),
  },
  mounted() {
    this.$refs.tileImage.addEventListener("load", (/*event*/) => {
      this.loading = false;
    });

    this.loading = true;
  },
};
</script>

<template>
  <div class="product-tile">
    <div class="top">
      <button
        v-if="!loading"
        type="button"
        class="favorites"
        :class="{ 'is-active': isInFavorites }"
        @click="toggleInFavorites"
      >
        <icon-favorites-30 />
      </button>
      <button
        v-if="!loading"
        type="button"
        class="preview"
        @click="openFastView"
      >
        <icon-search class="icon" />{{ $s("STR_QUICK_VIEW") }}
      </button>
      <a class="image-wrap" :href="currentColorUrl">
        <div
          v-if="product.stickers && product.stickers.length > 0"
          class="stickers"
        >
          <div
            v-for="sticker in product.stickers"
            class="sticker"
            :style="{ backgroundColor: sticker.color }"
          >
            {{ sticker.title }}
          </div>
        </div>
        <picture class="image-wrap2">
          <source
            v-for="source in currentColor.cover_sources"
            :srcset="source.srcset"
            :media="source.media"
            :type="source.type"
            :key="JSON.stringify(source)"
          />
          <img
            class="image"
            :src="currentColor.cover_src || '/images/product-default.svg'"
            ref="tileImage"
          />
        </picture>
        <div
          v-if="currentColor && currentColor.cover_src"
          class="loading"
          :class="{ show: loading }"
        ></div>
      </a>
      <div class="colors">
        <template v-for="(color, index) in filteredColors" :key="color.uuid">
          <button
            v-if="color.color_image && color.color_image != 'null'"
            type="button"
            class="color"
            :class="{ selected: currentColorIndex == index }"
            :style="{
              backgroundImage: 'url(/download/files/' + color.color_image + ')',
            }"
            :title="color.title"
            @click="setCurrentColor(index)"
          ></button>
          <button
            v-else
            type="button"
            class="color"
            :class="{ selected: currentColorIndex == index }"
            :style="{
              backgroundColor: color.color || '#000000',
            }"
            :title="color.title"
            @click="setCurrentColor(index)"
          ></button>
        </template>
      </div>
    </div>
    <a class="bottom" :href="currentColorUrl">
      <div class="series">{{ product.collection_title }}</div>
      <div class="title">
        {{ product.description }} {{ product.size_title }} |
        {{ product.size }} | {{ product.weight }}
      </div>
      <div class="prices">
        <span v-if="currentColor.price" class="current-price">{{
          $moneyFormat(currentColor.price)
        }}</span>
        <span v-if="currentColor.price_old" class="old-price">{{
          $moneyFormat(currentColor.price_old)
        }}</span>
      </div>
    </a>
    <div v-if="сanBeBought" class="buy-wrapper">
      <button
        v-if="!isProductInCart(product.id, currentColor.uuid)"
        class="buy"
        @click="buy"
      >
        {{ $s("STR_BUY") }}
      </button>
      <button v-else class="buy" @click="buy">
        {{ $s("STR_ALREADY_IN_CART") }}
      </button>
    </div>
  </div>
</template>
